var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("visitor-support-requests-table", {
        attrs: {
          reloadOrders: _vm.reloadOrders,
          reloadOrdersDelete: _vm.reloadOrdersDelete
        },
        on: {
          selectedOrders: _vm.selectedOrders,
          reloadOrdersDone: function($event) {
            _vm.reloadOrders = $event
          },
          reloadOrdersDeleteDone: function($event) {
            _vm.reloadOrdersDelete = $event
          }
        }
      }),
      _c("SideButtons", {
        attrs: {
          reset: _vm.resetForm,
          canEdit:
            _vm.canEdit || _vm.visitorSupportData.statusPlain == "approved",
          select: _vm.have,
          save: _vm.send,
          ODS: false,
          service_order: false
        },
        on: {
          info: function($event) {
            _vm.info = $event
          },
          add: function($event) {
            _vm.add = $event
          },
          save: function($event) {
            _vm.save = $event
          },
          saveToPending: function($event) {
            _vm.saveToPending = $event
          },
          edit: function($event) {
            _vm.edit = $event
          },
          SaveEdit: function($event) {
            _vm.SaveEdit = $event
          },
          confirm: function($event) {
            _vm.confirm = $event
          },
          reject: function($event) {
            _vm.reject = $event
          },
          validate: function($event) {
            _vm.validate = $event
          },
          resetEmit: function($event) {
            _vm.resetForm = $event
          },
          delete: function($event) {
            _vm.destroy = $event
          }
        }
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showForm,
              expression: "showForm"
            }
          ]
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-lg-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c(
                    "form",
                    {
                      staticClass: "needs-validation",
                      attrs: { enctype: "multipart/form-data" },
                      on: {
                        submit: function($event) {
                          $event.preventDefault()
                          return _vm.formSubmit($event)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-4" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "formrow-password-input" } },
                                [_vm._v("Motif de la demande *")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.visitorSupportData.reason,
                                    expression: "visitorSupportData.reason"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: {
                                  value: _vm.visitorSupportData.reason
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.visitorSupportData,
                                      "reason",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-2" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c(
                                "label",
                                { attrs: { for: "formrow-inputCity" } },
                                [_vm._v("Date *")]
                              ),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.visitorSupportData.date,
                                    expression: "visitorSupportData.date"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "date", name: "", id: "" },
                                domProps: {
                                  value: _vm.visitorSupportData.date
                                },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.visitorSupportData,
                                      "date",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "formrow-inputCity" } },
                                  [_vm._v("Type de structure *")]
                                ),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "name",
                                    options: _vm.structureTypes,
                                    placeholder: "Select user structure",
                                    "allow-empty": false,
                                    "select-label": "",
                                    "deselect-label": ""
                                  },
                                  on: { input: _vm.onchangeStructureType },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleStruct",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [_vm._v(_vm._s(type.name))]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value:
                                      _vm.visitorSupportData.structure_type,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "structure_type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "visitorSupportData.structure_type"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-3" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "formrow-password-input" } },
                                  [_vm._v("Structure d'accueil *")]
                                ),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "name",
                                    options: _vm.structures,
                                    placeholder: "Select user structure",
                                    "allow-empty": false,
                                    "select-label": "",
                                    "deselect-label": ""
                                  },
                                  on: { close: _vm.getImputation },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleStruct",
                                      fn: function(ref) {
                                        var struct = ref.struct
                                        return [_vm._v(_vm._s(struct.name))]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.visitorSupportData.structure,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "structure",
                                        $$v
                                      )
                                    },
                                    expression: "visitorSupportData.structure"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-2" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Imputation *")
                              ]),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.visitorSupportData.analyticAccount,
                                      expression:
                                        "visitorSupportData.analyticAccount"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "analyticAccount",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                _vm._l(_vm.analyticList, function(ana) {
                                  return _c(
                                    "option",
                                    {
                                      key: ana.account,
                                      domProps: { value: ana.account }
                                    },
                                    [_vm._v(" " + _vm._s(ana.account))]
                                  )
                                }),
                                0
                              )
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Attachement ")
                                ]),
                                _c(
                                  "vue-dropzone",
                                  {
                                    ref: "myVueDropzone",
                                    staticClass: "ebs-upload",
                                    attrs: {
                                      id: "dropzone",
                                      "use-custom-slot": true,
                                      options: _vm.dropzoneOptions
                                    },
                                    on: {
                                      "vdropzone-complete": _vm.afterComplete
                                    },
                                    model: {
                                      value: _vm.invoiceAttachment,
                                      callback: function($$v) {
                                        _vm.invoiceAttachment = $$v
                                      },
                                      expression: "invoiceAttachment"
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "dropzone-custom-content"
                                      },
                                      [
                                        _c("i", {
                                          staticClass:
                                            "display-4 text-muted fas fa-upload"
                                        })
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-2" },
                          [
                            _c("div", { staticClass: "form-group " }, [
                              _c(
                                "label",
                                { attrs: { for: "formrow-password-input " } },
                                [_vm._v("Date d'arrivée *")]
                              ),
                              _c("div", { staticClass: "row" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.visitorSupportData.dateIn,
                                      expression: "visitorSupportData.dateIn"
                                    }
                                  ],
                                  staticClass:
                                    "form-control col-xs-12 col-md-7",
                                  attrs: { type: "date" },
                                  domProps: {
                                    value: _vm.visitorSupportData.dateIn
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "dateIn",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.visitorSupportData.time_in,
                                      expression: "visitorSupportData.time_in"
                                    }
                                  ],
                                  staticClass:
                                    "form-control col-xs-12 col-md-4 ml-1",
                                  attrs: { type: "time" },
                                  domProps: {
                                    value: _vm.visitorSupportData.time_in
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "time_in",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "col-xs-12 col-md-4 col-lg-2" },
                          [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Date de sortie *")
                              ]),
                              _c("div", { staticClass: "row" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.visitorSupportData.dateOut,
                                      expression: "visitorSupportData.dateOut"
                                    }
                                  ],
                                  staticClass:
                                    "form-control col-xs-12 col-md-7",
                                  attrs: { type: "date" },
                                  domProps: {
                                    value: _vm.visitorSupportData.dateOut
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "dateOut",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.visitorSupportData.time_out,
                                      expression: "visitorSupportData.time_out"
                                    }
                                  ],
                                  staticClass:
                                    "form-control col-xs-12 col-md-4 ml-1",
                                  attrs: { type: "time" },
                                  domProps: {
                                    value: _vm.visitorSupportData.time_out
                                  },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "time_out",
                                        $event.target.value
                                      )
                                    }
                                  }
                                })
                              ])
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "form-group col-xs-12 col-md-4 col-lg-4"
                          },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Services *")
                            ]),
                            _c(
                              "div",
                              { staticClass: "row justify-content-md-center" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "inlb col-md-4",
                                    attrs: { switch: "" },
                                    model: {
                                      value: _vm.visitorSupportData.needRest,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.visitorSupportData,
                                          "needRest",
                                          $$v
                                        )
                                      },
                                      expression: "visitorSupportData.needRest"
                                    }
                                  },
                                  [_vm._v("Restauration")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "inlb col-md-4",
                                    attrs: { switch: "" },
                                    model: {
                                      value: _vm.visitorSupportData.needHosting,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.visitorSupportData,
                                          "needHosting",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "visitorSupportData.needHosting"
                                    }
                                  },
                                  [_vm._v("Hébergement")]
                                ),
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "inlb col-md-3",
                                    attrs: { switch: "" },
                                    model: {
                                      value:
                                        _vm.visitorSupportData.needTransport,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.visitorSupportData,
                                          "needTransport",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "visitorSupportData.needTransport"
                                    }
                                  },
                                  [_vm._v("Transport")]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col" }, [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Type *")
                              ]),
                              _c("multiselect", {
                                attrs: {
                                  searchable: true,
                                  "track-by": "id",
                                  label: "name",
                                  options: _vm.destinationTypes,
                                  "select-label": "",
                                  "deselect-label": "",
                                  placeholder: "Selectionner",
                                  "allow-empty": false
                                },
                                on: { close: _vm.getEmployees },
                                scopedSlots: _vm._u([
                                  {
                                    key: "singleMealType",
                                    fn: function(ref) {
                                      var type = ref.type
                                      return [_vm._v(_vm._s(type.name))]
                                    }
                                  }
                                ]),
                                model: {
                                  value: _vm.visitorSupportData.location_type,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.visitorSupportData,
                                      "location_type",
                                      $$v
                                    )
                                  },
                                  expression: "visitorSupportData.location_type"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm.visitorSupportData.location_type.id == "external"
                          ? _c("div", { staticClass: "col-xs-12 col-md" }, [
                              _c(
                                "div",
                                { staticClass: "form-group" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between",
                                      attrs: { for: "" }
                                    },
                                    [
                                      _c("span", [_vm._v(" Entreprise *")]),
                                      _vm._v(" "),
                                      _c(
                                        "small",
                                        {
                                          staticClass: "text-primary",
                                          staticStyle: { cursor: "pointer" },
                                          on: {
                                            click: function($event) {
                                              _vm.addExtraModal = true
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fas fa-plus-circle"
                                          }),
                                          _vm._v(" Ajouter")
                                        ]
                                      )
                                    ]
                                  ),
                                  _c("multiselect", {
                                    attrs: {
                                      searchable: true,
                                      "track-by": "id",
                                      label: "company_name",
                                      options: _vm.List_Company,
                                      "select-label": "",
                                      "deselect-label": "",
                                      placeholder: "Selectionner",
                                      "allow-empty": false
                                    },
                                    on: { close: _vm.getVisitor },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "singleMealType",
                                          fn: function(ref) {
                                            var type = ref.type
                                            return [
                                              _vm._v(_vm._s(type.company_name))
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      821789964
                                    ),
                                    model: {
                                      value: _vm.visitorSupportData.companyName,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.visitorSupportData,
                                          "companyName",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "visitorSupportData.companyName"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.visitorSupportData.location_type.id ==
                                  "intra_company",
                                expression:
                                  'visitorSupportData.location_type.id == "intra_company"'
                              }
                            ],
                            staticClass: "col-xs-12 col-md"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Structure *")
                                ]),
                                _c("multiselect", {
                                  attrs: {
                                    searchable: true,
                                    "track-by": "id",
                                    label: "intra_entreprises_name",
                                    options: _vm.List_Company,
                                    "select-label": "",
                                    "deselect-label": "",
                                    placeholder: "Selectionner",
                                    "allow-empty": false
                                  },
                                  on: { close: _vm.getVisitor },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "singleMealType",
                                      fn: function(ref) {
                                        var type = ref.type
                                        return [
                                          _vm._v(
                                            _vm._s(type.intra_entreprises_name)
                                          )
                                        ]
                                      }
                                    }
                                  ]),
                                  model: {
                                    value: _vm.visitorSupportData.companyName,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.visitorSupportData,
                                        "companyName",
                                        $$v
                                      )
                                    },
                                    expression: "visitorSupportData.companyName"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.visitorSupportData.location_type.id ==
                                  "intra_company",
                                expression:
                                  'visitorSupportData.location_type.id == "intra_company"'
                              }
                            ],
                            staticClass: "col-xs-12 col-md-2"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c(
                                  "b-form-checkbox",
                                  {
                                    staticClass: "mb-3 mt-3",
                                    attrs: { switch: "", value: 1 },
                                    model: {
                                      value: _vm.visitorSupportData.is_visitor,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.visitorSupportData,
                                          "is_visitor",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "visitorSupportData.is_visitor"
                                    }
                                  },
                                  [_vm._v(" Invite ")]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.visitorSupportData.location_type.id ==
                                    "intra_company" &&
                                  _vm.visitorSupportData.is_visitor == "0",
                                expression:
                                  'visitorSupportData.location_type.id == "intra_company" && visitorSupportData.is_visitor =="0" '
                              }
                            ],
                            staticClass: "col-xs-12 col-md"
                          },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v("Imputation *")
                            ]),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.visitorSupportData.analyticAccount,
                                    expression:
                                      "visitorSupportData.analyticAccount"
                                  }
                                ],
                                staticClass: "form-control",
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.visitorSupportData,
                                      "analyticAccount",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.analyticListIntra, function(ana) {
                                return _c(
                                  "option",
                                  {
                                    key: ana.account,
                                    domProps: { value: ana.account }
                                  },
                                  [_vm._v(" " + _vm._s(ana.account))]
                                )
                              }),
                              0
                            )
                          ]
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "row d-flex align-items-center" },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c("h3", [_vm._v("Visiteurs: ")])
                          ]),
                          _c("div", { staticClass: "col text-right" }, [
                            _vm.visitorSupportData.companyName.id
                              ? _c(
                                  "a",
                                  {
                                    staticClass: "mt-3",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        _vm.addVisitorModal = true
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-plus-circle"
                                    }),
                                    _vm._v(" Ajouter")
                                  ]
                                )
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c("hr"),
                      _c(
                        "div",
                        [
                          _vm._l(_vm.visitorSupportData.visitors, function(
                            visitor,
                            index
                          ) {
                            return _c(
                              "div",
                              { key: visitor.id, staticClass: "row" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "form-group col-lg-3" },
                                  [
                                    _c("label", { attrs: { for: "message" } }, [
                                      _vm._v("Nom complet")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        "select-label": "",
                                        "deselect-label": "",
                                        searchable: true,
                                        "track-by": "id",
                                        label: "detailInfo",
                                        options: _vm.list_visitor,
                                        placeholder: "Selectionner",
                                        "allow-empty": false
                                      },
                                      on: { select: _vm.deleteUserFromList },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleMealType",
                                            fn: function(ref) {
                                              var type = ref.type
                                              return [
                                                _vm._v(_vm._s(type.detailInfo))
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: visitor.full_name,
                                        callback: function($$v) {
                                          _vm.$set(visitor, "full_name", $$v)
                                        },
                                        expression: "visitor.full_name"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-12 col-md-2 col-lg-2"
                                  },
                                  [
                                    _c("label", { attrs: { for: "resume" } }, [
                                      _vm._v("Pièce d'identité")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.identityDocTypes,
                                        "select-label": "",
                                        "deselect-label": "",
                                        placeholder: "Selectionner",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleMealType",
                                            fn: function(ref) {
                                              var type = ref.type
                                              return [_vm._v(_vm._s(type.name))]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: visitor.identityDocType,
                                        callback: function($$v) {
                                          _vm.$set(
                                            visitor,
                                            "identityDocType",
                                            $$v
                                          )
                                        },
                                        expression: "visitor.identityDocType"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-2 col-md-2 col-lg-2"
                                  },
                                  [
                                    _c("label", { attrs: { for: "subject" } }, [
                                      _vm._v("N° Identité")
                                    ]),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: visitor.identityDocNo,
                                          expression: "visitor.identityDocNo"
                                        }
                                      ],
                                      staticClass: "form-control",
                                      attrs: { id: "subject", type: "text" },
                                      domProps: {
                                        value: visitor.identityDocNo
                                      },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.$set(
                                            visitor,
                                            "identityDocNo",
                                            $event.target.value
                                          )
                                        }
                                      }
                                    })
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-6 col-md-2 col-lg-2 "
                                  },
                                  [
                                    _c("label", { attrs: { for: "message" } }, [
                                      _vm._v("Type de prise en charge")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        "select-label": "",
                                        "deselect-label": "",
                                        options: _vm.supportTypes,
                                        placeholder: "Selectionner",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleMealType",
                                            fn: function(ref) {
                                              var type = ref.type
                                              return [_vm._v(_vm._s(type.name))]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: visitor.supportType,
                                        callback: function($$v) {
                                          _vm.$set(visitor, "supportType", $$v)
                                        },
                                        expression: "visitor.supportType"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "form-group col-sm-12 col-md-2 col-lg-2"
                                  },
                                  [
                                    _c("label", { attrs: { for: "resume" } }, [
                                      _vm._v("Type de Restauration")
                                    ]),
                                    _c("multiselect", {
                                      attrs: {
                                        searchable: true,
                                        "track-by": "id",
                                        label: "name",
                                        options: _vm.restaurationTypes,
                                        "select-label": "",
                                        "deselect-label": "",
                                        placeholder: "Selectionner",
                                        "allow-empty": false
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "singleMealType",
                                            fn: function(ref) {
                                              var type = ref.type
                                              return [_vm._v(_vm._s(type.name))]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      ),
                                      model: {
                                        value: visitor.restaurationTypes,
                                        callback: function($$v) {
                                          _vm.$set(
                                            visitor,
                                            "restaurationTypes",
                                            $$v
                                          )
                                        },
                                        expression: "visitor.restaurationTypes"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "col-lg-1 align-self-center" },
                                  [
                                    _vm.visitorSupportData.visitors.length >
                                      1 && index != 0
                                      ? _c(
                                          "a",
                                          {
                                            attrs: {
                                              href: "javascript:void(0)"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "bx bx-trash-alt",
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteRow(index)
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            )
                          }),
                          _c("input", {
                            staticClass: "btn btn-success mt-3 mt-lg-0",
                            attrs: { type: "button", value: "Ajouter" },
                            on: { click: _vm.AddformData }
                          })
                        ],
                        2
                      ),
                      _c("br"),
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-12" }, [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Commentaires")]
                            ),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.visitorSupportData.notes,
                                  expression: "visitorSupportData.notes"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { cols: "30", rows: "1" },
                              domProps: { value: _vm.visitorSupportData.notes },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.visitorSupportData,
                                    "notes",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ])
                        ])
                      ]),
                      _c("div", { staticClass: "row" }, [
                        _c(
                          "div",
                          { staticClass: "col" },
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "inlb col-md-3",
                                attrs: { switch: "" },
                                model: {
                                  value: _vm.visitorSupportData.needs_dr,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.visitorSupportData,
                                      "needs_dr",
                                      $$v
                                    )
                                  },
                                  expression: "visitorSupportData.needs_dr"
                                }
                              },
                              [_vm._v("Requière Validation DR")]
                            )
                          ],
                          1
                        )
                      ]),
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-light btn-label float-right ml-2",
                            attrs: { type: "button" },
                            on: { click: _vm.cancelBtn }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-times-circle text-danger label-icon"
                            }),
                            _vm._v(" Annuler ")
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-label btn-primary float-right",
                            attrs: {
                              type: "submit",
                              disabled: _vm.$v.visitorSupportData.$invalid
                            }
                          },
                          [
                            _c("i", { staticClass: "far fa-save label-icon " }),
                            _vm._v(" Enregistrer ")
                          ]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ])
          ])
        ]
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showForm,
              expression: "!showForm"
            }
          ]
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-xl-12" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card-body" }, [
                  _c("h4", { staticClass: "card-title mb-4" }, [
                    _vm._v("Détails "),
                    (_vm.visitorSupportData.statusPlain == "draft" ||
                      _vm.visitorSupportData.statusPlain == "confirmed") &&
                    _vm.$can("edit_vsr")
                      ? _c(
                          "div",
                          { staticClass: "button-items float-right mr-5" },
                          [
                            _vm.visitorSupportData.statusPlain == "confirmed" &&
                            _vm.visitorSupportData.needs_dr &&
                            _vm.$can("super_approve_vsr")
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-label ",
                                    attrs: { variant: "success" },
                                    on: { click: _vm.validationByDR }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bx-check-double font-size-16 align-middle mr-2 text-white label-icon"
                                    }),
                                    _vm._v(" Validation DR ")
                                  ]
                                )
                              : _vm._e(),
                            (_vm.visitorSupportData.statusPlain ==
                              "confirmed" &&
                              _vm.$can("approve_vsr") &&
                              !_vm.visitorSupportData.needs_dr) ||
                            (_vm.visitorSupportData.statusPlain ==
                              "confirmed" &&
                              _vm.$can("approve_vsr") &&
                              _vm.visitorSupportData.needs_dr &&
                              _vm.visitorSupportData.dr_validation)
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.approvalModal",
                                        modifiers: { approvalModal: true }
                                      }
                                    ],
                                    staticClass: "btn-label ",
                                    attrs: { variant: "success" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bx-check-double font-size-16 align-middle mr-2 text-white label-icon"
                                    }),
                                    _vm._v(" Approuver ")
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.visitorSupportData.statusPlain ==
                                        "draft" && _vm.$can("confirm_vsr"),
                                    expression:
                                      "visitorSupportData.statusPlain == 'draft'  && $can('confirm_vsr')"
                                  }
                                ],
                                attrs: { variant: "info btn-label" },
                                on: { click: _vm.confirmRequest }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "bx bx-check font-size-16 align-middle mr-2  label-icon"
                                }),
                                _vm._v(" Confirmer ")
                              ]
                            ),
                            ((_vm.visitorSupportData.statusPlain == "draft" ||
                              _vm.visitorSupportData.statusPlain ==
                                "confirmed") &&
                              _vm.$can("reject_vsr")) ||
                            (_vm.visitorSupportData.statusPlain ==
                              "confirmed" &&
                              _vm.visitorSupportData.needs_dr &&
                              _vm.$can("reject_vsr") &&
                              _vm.$can("super_approve_vsr"))
                              ? _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-modal",
                                        rawName: "v-b-modal.rejectionModal",
                                        modifiers: { rejectionModal: true }
                                      }
                                    ],
                                    staticClass: "btn-label",
                                    attrs: { variant: "danger" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "bx bx-block font-size-16 align-middle mr-2 text-white label-icon"
                                    }),
                                    _vm._v(" Rejeter ")
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ]),
                  _c("p", { attrs: { lass: "text-muted mb-4" } }),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Demandeur :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.visitorSupportData.requestedBy)
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Structure d'accueil :")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.visitorSupportData.structure_type[
                                        "name"
                                      ]
                                    ) +
                                    " : " +
                                    _vm._s(
                                      _vm.visitorSupportData.structure["name"]
                                    ) +
                                    " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Imputation :")]),
                              _c("td", [
                                _c("b", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.visitorSupportData.analyticAccount
                                    )
                                  )
                                ])
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Date d'arrivée :")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.visitorSupportData.dateIn_format
                                    ) +
                                    " " +
                                    _vm._s(_vm.visitorSupportData.time_in) +
                                    " "
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Motif de la demande :")]),
                              _c("td", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.visitorSupportData.reason) +
                                    " "
                                )
                              ])
                            ]),
                            (_vm.visitorSupportData.statusPlain ==
                              "confirmed" ||
                              _vm.visitorSupportData.statusPlain ==
                                "approved") &&
                            _vm.visitorSupportData.supervisor
                              ? _c("tr", [
                                  _c("th", [_vm._v("Supervisé par :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.visitorSupportData.supervisor)
                                    )
                                  ])
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "table-responsive mb-0" }, [
                        _c("table", { staticClass: "table" }, [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Prestations :")]),
                              _c("td", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.visitorSupportData.prestations
                                    )
                                  }
                                })
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Attachement :")]),
                              _c("td", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        _vm.visitorSupportData.ov_file.fullPath,
                                      target: "_blank"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.visitorSupportData.ov_file.name
                                      ) + " "
                                    ),
                                    _c("i", { staticClass: "fas fa-download" })
                                  ]
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Date de demande :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.visitorSupportData.requestDate_format
                                  )
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Date de sortie :")]),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.visitorSupportData.dateOut_format
                                  ) +
                                    " " +
                                    _vm._s(_vm.visitorSupportData.time_out)
                                )
                              ])
                            ]),
                            _c("tr", [
                              _c("th", [_vm._v("Etat :")]),
                              _c("td", [
                                _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.visitorSupportData.status
                                    )
                                  }
                                })
                              ])
                            ]),
                            _vm.visitorSupportData.statusPlain == "rejected"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Rejeté par :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.visitorSupportData.rejectedBy)
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.visitorSupportData.statusPlain == "rejected"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Date de rejet:")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.visitorSupportData
                                          .rejectionDate_format
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.visitorSupportData.statusPlain == "rejected"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Raison de rejet:")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.visitorSupportData.rejectionReason
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.visitorSupportData.statusPlain == "approved"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Approuvée Le :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.visitorSupportData.approvedAt_format
                                      )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.visitorSupportData.statusPlain == "approved"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Approuvée par :")]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.visitorSupportData.approvedBy)
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm.visitorSupportData.statusPlain == "approved"
                              ? _c("tr", [
                                  _c("th", [_vm._v("Bon de Livraison :")]),
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "ops.receptions.display",
                                              params: {
                                                uid:
                                                  _vm.visitorSupportData
                                                    .reception.uuid
                                              }
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.visitorSupportData.reception
                                                .ref
                                            )
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      ])
                    ])
                  ]),
                  _c("hr"),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-lg-12" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Visiteurs: ")
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm.visitorSupportData.location_type.id == "external"
                        ? _c("h5", [
                            _vm._v(
                              "Entreprise: " +
                                _vm._s(
                                  _vm.visitorSupportData.companyName[
                                    "company_name"
                                  ]
                                )
                            )
                          ])
                        : _c("h5", [
                            _vm._v(
                              "Structure : " +
                                _vm._s(
                                  _vm.visitorSupportData.companyName[
                                    "intra_entreprises_name"
                                  ]
                                )
                            )
                          ]),
                      _c("p", { staticClass: "card-title-desc" }, [
                        _vm._v(_vm._s(_vm.visitorSupportData.notes))
                      ]),
                      _c("div", { staticClass: "table-responsive" }, [
                        _c(
                          "table",
                          { staticClass: "table table-striped mb-0" },
                          [
                            _c("thead", [
                              _c("tr", [
                                _c("th", [_vm._v("Nom complet")]),
                                _c("th", [_vm._v("N° Pièce d'identité")]),
                                _c("th", [_vm._v("Type de prise en charge")]),
                                _c("th", [_vm._v("Type de Restauration")])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.visitorSupportData.entries, function(
                                entry
                              ) {
                                return _c("tr", { key: entry.id }, [
                                  _c("td", [_vm._v(_vm._s(entry.detailInfo))]),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(entry.identityDocYype) + " N°: "
                                    ),
                                    _c("b", [
                                      _vm._v(_vm._s(entry.identityDocNo))
                                    ])
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(entry.supportType.name))
                                  ]),
                                  _c("td", [
                                    _vm._v(_vm._s(entry.restaurationTypes.name))
                                  ])
                                ])
                              }),
                              0
                            )
                          ]
                        )
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _c("approval-modal", {
            attrs: {
              camps: _vm.campsList,
              requestData: _vm.visitorSupportData,
              modelUid: _vm.visitorSupportData.id,
              modelRef: _vm.visitorSupportData.ref
            }
          }),
          _c("rejection-modal", {
            attrs: {
              modelRef: _vm.visitorSupportData.ref,
              modelId: _vm.visitorSupportData.id,
              modelType: _vm.visitorSupportData.modelType
            }
          }),
          _c(
            "Modal",
            {
              attrs: { title: "Ajouter Nouvelle Entreprise Extra SH" },
              model: {
                value: _vm.addExtraModal,
                callback: function($$v) {
                  _vm.addExtraModal = $$v
                },
                expression: "addExtraModal"
              }
            },
            [
              _c("fieldset", [
                _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    attrs: { enctype: "multipart/form-data" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addExtra($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-lg-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Nom d'entreprise *")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Company_info.Company_name,
                                expression: "Company_info.Company_name"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.Company_info.Company_name.$error
                            },
                            attrs: { type: "text", placeholder: "" },
                            domProps: { value: _vm.Company_info.Company_name },
                            on: {
                              blur: _vm.$v.Company_info.Company_name.$error,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Company_info,
                                  "Company_name",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.submitted &&
                          _vm.$v.Company_info.Company_name.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.Company_info.Company_name.required
                                  ? _c("span", [
                                      _vm._v("Le champ est obligatoire.")
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "col-lg-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Télèphone *")
                          ]),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Company_info.Company_phone,
                                expression: "Company_info.Company_phone"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.Company_info.Company_phone.$error
                            },
                            attrs: { type: "text", placeholder: "" },
                            domProps: { value: _vm.Company_info.Company_phone },
                            on: {
                              blur: _vm.$v.Company_info.Company_phone.$error,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Company_info,
                                  "Company_phone",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.submitted &&
                          _vm.$v.Company_info.Company_phone.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.Company_info.Company_phone.required
                                  ? _c("span", [
                                      _vm._v(" Le champ est obligatoire.")
                                    ])
                                  : _vm._e(),
                                _c("br"),
                                !_vm.$v.Company_info.Company_phone.numeric
                                  ? _c("span", [
                                      _vm._v(
                                        "le Numréo de Télèphone doit étre un nombre ."
                                      )
                                    ])
                                  : _vm._e(),
                                _c("br"),
                                !_vm.$v.Company_info.Company_phone.minLength
                                  ? _c("span", [
                                      _vm._v(
                                        "La taille minimum du champs est: 9 nombres ."
                                      )
                                    ])
                                  : _vm._e(),
                                _c("br"),
                                !_vm.$v.Company_info.Company_phone.maxLength
                                  ? _c("span", [
                                      _vm._v(
                                        "La taille maximum du champs est: 10 nombre ."
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      ]),
                      _c("div", { staticClass: "col-lg-4" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "label",
                            { attrs: { for: "formrow-password-input" } },
                            [_vm._v("Adresse")]
                          ),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Company_info.Company_adr,
                                expression: "Company_info.Company_adr"
                              }
                            ],
                            staticClass: "form-control",
                            class: {
                              "is-invalid":
                                _vm.submitted &&
                                _vm.$v.Company_info.Company_adr.$error
                            },
                            attrs: { type: "text", placeholder: "" },
                            domProps: { value: _vm.Company_info.Company_adr },
                            on: {
                              blur: _vm.$v.Company_info.Company_adr.$error,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Company_info,
                                  "Company_adr",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.submitted &&
                          _vm.$v.Company_info.Company_adr.$error
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                !_vm.$v.Company_info.Company_adr.required
                                  ? _c("span", [
                                      _vm._v("Le champ est obligatoire.")
                                    ])
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { attrs: { for: "formrow-inputCity" } }, [
                            _vm._v("Commentaires *")
                          ]),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.Company_info.comments,
                                expression: "Company_info.comments"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { cols: "30", rows: "1" },
                            domProps: { value: _vm.Company_info.comments },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.Company_info,
                                  "comments",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ])
                      ])
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12 " }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-light btn-label float-right ml-2",
                            attrs: { type: "button" },
                            on: { click: _vm.clearForm }
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fas fa-times-circle text-danger label-icon"
                            }),
                            _vm._v(" Annuler ")
                          ]
                        ),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-label btn-primary float-right",
                            attrs: { type: "submit" }
                          },
                          [
                            _c("i", { staticClass: "far fa-save label-icon " }),
                            _vm._v(" Enregistrer ")
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ]
          ),
          _c(
            "Modal",
            {
              attrs: {
                title:
                  "Ajouter un nouveau visiteur à " +
                  _vm.visitorSupportData.companyName.company_name
              },
              model: {
                value: _vm.addVisitorModal,
                callback: function($$v) {
                  _vm.addVisitorModal = $$v
                },
                expression: "addVisitorModal"
              }
            },
            [
              _c("fieldset", { attrs: { disabled: _vm.disabled } }, [
                _c(
                  "form",
                  {
                    staticClass: "needs-validation",
                    attrs: { enctype: "multipart/form-data" },
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.addVisitor($event)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-4" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Nom de visiteur *")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.Visitor_info.Visitor_fullname,
                                  expression: "Visitor_info.Visitor_fullname"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.submitted &&
                                  _vm.$v.Visitor_info.Visitor_fullname.$error
                              },
                              attrs: { type: "text", placeholder: "" },
                              domProps: {
                                value: _vm.Visitor_info.Visitor_fullname
                              },
                              on: {
                                blur:
                                  _vm.$v.Visitor_info.Visitor_fullname.$error,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.Visitor_info,
                                    "Visitor_fullname",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm.submitted &&
                            _vm.$v.Visitor_info.Visitor_fullname.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.Visitor_info.Visitor_fullname.required
                                    ? _c("span", [
                                        _vm._v("Le champ est obligatoire.")
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-4" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-inputCity" } },
                              [_vm._v("Numéro de carte d'identitie *")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.Visitor_info
                                      .Visitor_identity_card_number,
                                  expression:
                                    "Visitor_info.Visitor_identity_card_number"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.submitted &&
                                  _vm.$v.Visitor_info
                                    .Visitor_identity_card_number.$error
                              },
                              attrs: { type: "text", placeholder: "" },
                              domProps: {
                                value:
                                  _vm.Visitor_info.Visitor_identity_card_number
                              },
                              on: {
                                blur:
                                  _vm.$v.Visitor_info
                                    .Visitor_identity_card_number.$error,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.Visitor_info,
                                    "Visitor_identity_card_number",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm.submitted &&
                            _vm.$v.Visitor_info.Visitor_identity_card_number
                              .$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.Visitor_info
                                    .Visitor_identity_card_number.required
                                    ? _c("span", [
                                        _vm._v("Le champ est obligatoire.")
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-4" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-email-input" } },
                              [_vm._v("Nationalité *")]
                            ),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.Visitor_info.nationality,
                                    expression: "Visitor_info.nationality"
                                  }
                                ],
                                staticClass: "form-control",
                                class: {
                                  "is-invalid":
                                    _vm.submitted &&
                                    _vm.$v.Visitor_info.nationality.$error,
                                  "isDisabled disabledBG": _vm.disabled
                                },
                                on: {
                                  change: function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.Visitor_info,
                                      "nationality",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  }
                                }
                              },
                              _vm._l(_vm.nationalitys, function(nat) {
                                return _c(
                                  "option",
                                  {
                                    key: nat.uuid,
                                    domProps: { value: nat.uuid }
                                  },
                                  [_vm._v(" " + _vm._s(nat.designation) + " ")]
                                )
                              }),
                              0
                            ),
                            _vm.submitted &&
                            _vm.$v.Visitor_info.nationality.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.Visitor_info.nationality.required
                                    ? _c("span", [
                                        _vm._v(" Le champ est obligatoire.")
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-12 col-md-4 col-lg-4" },
                        [
                          _c("div", { staticClass: "form-group" }, [
                            _c(
                              "label",
                              { attrs: { for: "formrow-password-input" } },
                              [_vm._v("Fonction")]
                            ),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.Visitor_info.Visitor_function,
                                  expression: "Visitor_info.Visitor_function"
                                }
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.submitted &&
                                  _vm.$v.Visitor_info.Visitor_function.$error
                              },
                              attrs: { type: "text", placeholder: "" },
                              domProps: {
                                value: _vm.Visitor_info.Visitor_function
                              },
                              on: {
                                blur:
                                  _vm.$v.Visitor_info.Visitor_function.$error,
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.Visitor_info,
                                    "Visitor_function",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm.submitted &&
                            _vm.$v.Visitor_info.Visitor_function.$error
                              ? _c("div", { staticClass: "invalid-feedback" }, [
                                  !_vm.$v.Visitor_info.Visitor_function.required
                                    ? _c("span", [
                                        _vm._v("La champ est obligatoire.")
                                      ])
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "col-xs-6 col-md-4 col-lg-2 mt-4" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                switch: "",
                                value: 1,
                                checked: "checked"
                              },
                              model: {
                                value: _vm.Visitor_info.active,
                                callback: function($$v) {
                                  _vm.$set(_vm.Visitor_info, "active", $$v)
                                },
                                expression: "Visitor_info.active"
                              }
                            },
                            [_vm._v("Visiteur Active")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-md-12" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-label btn-primary float-right",
                            attrs: { type: "submit" }
                          },
                          [
                            _c("i", { staticClass: "far fa-save label-icon " }),
                            _vm._v(" Enregistrer ")
                          ]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }